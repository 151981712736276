import React from "react";
import { Title } from "./text";
import numeral from "numeral";
import { Panel, PanelGroup, Placeholder } from "rsuite";
import { BiLock } from "react-icons/bi";
import PaypalBTN, { Cardpay, UnionPay } from "./paypalBTN";
import { useDispatch, useSelector } from "react-redux";
import { makePayment } from "../features/utils/modalSlice";

function Payment() {
  const total_price = useSelector((state) => state.cart.total_amount);
  const dispatch = useDispatch();

  const onMakePay = (payload) => {
    dispatch(makePayment(payload));
  };

  return (
    <div className="mt-10 bg-gray-50 px-4 py-8 sticky top-[0vh] xl:lg:w-10/12  mx-auto  lg:col-span-5">
      <div className="flex justify-between pb-4">
        <p className="text-xl font-medium ">Payment Method</p>
      </div>
      <p className="text-gray-400 pb-5">
        Complete your order by providing your payment details.
      </p>
      <div className="">
        <div className="mt-6 border-t border-b py-2">
          <div className="flex items-center justify-between">
            <p className="text-sm font-light text-gray-900">Subtotal</p>
            <p className=" text-gray-900"> ${numeral(total_price).format()}</p>
          </div>
          <div className="flex items-center justify-between">
            <p className="text-sm font-light text-gray-900">Shipping</p>
            <p className=" text-gray-900">$0.00</p>
          </div>
          <div className="flex items-center justify-between">
            <p className="text-sm font-light text-gray-900">Discount</p>
            <p className=" text-gray-900">$0.00</p>
          </div>
        </div>
        <div className="mt-6 mb-5 flex items-center justify-between">
          <p className="text-sm font-medium text-gray-900">Total</p>
          <p className="text-2xl font-semibold text-gray-900">
            ${numeral(total_price).format()}
          </p>
        </div>
        <div className="flex flex-col justify-center  mx-auto gap-x-4 gap-y-4 text-center">
          <Cardpay  onPay={onMakePay} />
          <UnionPay onPay={onMakePay} />
          <PaypalBTN bg={"bg-slate-300"} onPay={onMakePay} />

          {/*
           <PanelGroup accordion defaultActiveKey={1} bordered>
        <Panel
          className="text-bold text-lg"
          header={
            <div className="flex items-center justify-start ">
              <input
                type="radio"
                size={40}
                name="debitCard"
                id="debitCard"
                className="mr-5"
              />
              <svg
                className="h-6 w-6 mx-1 text-gray-400 "
                width="20"
                height="20"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M11 5.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1z" />
                <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2zm13 2v5H1V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1zm-1 9H2a1 1 0 0 1-1-1v-1h14v1a1 1 0 0 1-1 1z" />
              </svg>
              <p className="font-semibold text-lg ">Credit/Debit Card</p>
            </div>
          }
          eventKey={1}
          id="panel1"
        >
          <label
            for="card-holder"
            className="mt-4 mb-2 block text-sm font-medium"
          >
            Card Holder
          </label>
          <div className="relative">
            <input
              type="text"
              id="card-holder"
              name="card-holder"
              className="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm uppercase shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              placeholder="Your full name here"
            />
            <div className="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z"
                />
              </svg>
            </div>
          </div>
          <label
            for="card-no"
            className="mt-4 mb-2 block text-sm font-medium"
          >
            Card Details
          </label>
          <div className="">
            <div className="relative  flex-shrink-0">
              <input
                type="text"
                id="card-no"
                name="card-no"
                className="w-full rounded-md border border-gray-200 px-2 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                placeholder="xxxx-xxxx-xxxx-xxxx"
              />
              <div className="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
                <svg
                  className="h-4 w-4 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M11 5.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1z" />
                  <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2zm13 2v5H1V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1zm-1 9H2a1 1 0 0 1-1-1v-1h14v1a1 1 0 0 1-1 1z" />
                </svg>
              </div>
            </div>
            <div className="flex pt-4 gap-x-3">
              <input
                type="text"
                name="credit-expiry"
                className="w-full rounded-md border border-gray-200 px-2 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                placeholder="MM/YY"
              />
              <input
                type="text"
                name="credit-cvc"
                className=" flex-shrink-0 rounded-md border border-gray-200 px-2 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                placeholder="CVC"
              />
            </div>
          </div>
        </Panel>
        <Panel
          header={
            <div className="flex items-center justify-start ">
              <input
                type="radio"
                size={40}
                name="debitCard"
                id="debitCard"
                className="mr-5"
              />
              <svg
                className="h-6 w-6 mx-1 text-gray-400 "
                width="20"
                height="20"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M11 5.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1z" />
                <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2zm13 2v5H1V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1zm-1 9H2a1 1 0 0 1-1-1v-1h14v1a1 1 0 0 1-1 1z" />
              </svg>
              <p className="font-semibold text-lg ">Google Pay</p>
            </div>
          }
          eventKey={2}
          id="panel2"
        >
          <Placeholder.Paragraph />
        </Panel>
        <Panel
          header={
            <div className="flex items-center justify-start ">
              <input
                type="radio"
                size={40}
                name="debitCard"
                id="debitCard"
                className="mr-5"
              />
              <svg
                className="h-6 w-6 mx-1 text-gray-400 "
                width="20"
                height="20"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M11 5.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1z" />
                <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2zm13 2v5H1V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1zm-1 9H2a1 1 0 0 1-1-1v-1h14v1a1 1 0 0 1-1 1z" />
              </svg>
              <p className="font-semibold text-lg ">Paypal</p>
            </div>
          }
          eventKey={4}
          id="panel4"
        >
          <Placeholder.Paragraph />
        </Panel>
      </PanelGroup> */}
        </div>
      </div>
    </div>
  );
}

export default Payment;
